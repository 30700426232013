import { createContext, useContext, useEffect, useState } from 'react';
import { SnackbarValue } from '../helpers/snackbar.server';

export type SnackbarContextType = {
  message: string | null;
  show: boolean;
  key: number;
  showSnackbarMessage: (message: string, index?: boolean) => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

/**
 * useSnackbar is a hook that provides access to the SnackbarContext
 *
 * It is used by the Snackbar component
 *
 * @example
 * export default function () {
 *  const { showSnackbarMessage } = useSnackbar();
 *
 *  return (
 *    <>
 *     <Snackbar />
 *     <button onClick={showSnackbarMessage('Hello World From Click')}>Click me!</button>
 *    </>
 *  );
 * }
 */
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  /**
   * Use this function to set the snackbar message after loader
   *
   * @example
   * export default function () {
   *  const { message } = useTypedLoaderData<typeof loader>();
   *  const { setSnackbarMessage } = useSnackbar();
   *  setSnackbarMessage(message);
   *
   *  return (
   *    <>
   *     <Snackbar />
   *    </>
   *  );
   * }
   *
   * @param message - The message to show in the snackbar
   */
  const setSnackbarMessage = (snackbar: SnackbarValue) => {
    useEffect(() => {
      if (snackbar) {
        context.showSnackbarMessage(snackbar.message);
      }
    }, [snackbar]);
  };

  /**
   * Use this function to show the snackbar message after fetcher submit
   *
   * @example
   * export default function () {
   *   const fetcher = useTypedFetcher<ActionResponse>();
   *   const { showFetcherMessage } = useSnackbar();
   *   const handleDelete = (id: string) => {
   *     fetcher.submit(
   *       { intent: 'delete', id },
   *       {
   *         method: 'delete',
   *         action: `/${id}`,
   *       },
   *     );
   *   };
   *
   *   showFetcherMessage(fetcher);
   *
   *   return (
   *    <>
   *     <Snackbar />
   *    </>
   *   );
   * }
   *
   * @param fetcher
   */
  const showFetcherMessage = (fetcher: any, onError?: (error: any) => void) => {
    useEffect(() => {
      if (fetcher.data && fetcher.data?.message) {
        context.showSnackbarMessage(fetcher.data.message);
      }

      if (onError && fetcher.data?.error) {
        onError(fetcher.data?.error);
      }
    }, [fetcher.data]);
  };

  return { ...context, setSnackbarMessage, showFetcherMessage };
};

type Props = {
  children: React.ReactNode;
};

/**
 * SnackbarProvider provides a context for showing a snackbar message
 *
 * It is used by the Snackbar component
 */
export const SnackbarProvider = ({ children }: Props) => {
  const [message, setMessage] = useState<string | null>(null);
  const [key, setKey] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  const showSnackbarMessage = (newMessage: string) => {
    setMessage(newMessage);
    setShow(true);
    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }

    return;
  }, [show]);

  return (
    <SnackbarContext.Provider
      value={{
        message,
        key,
        show,
        showSnackbarMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
