import { useSnackbar } from '../hooks/useSnackbar';
import { clsx } from 'clsx';

type Props = {
  className?: string;
};

/**
 * Snackbar is a component that shows a message at the top of the screen
 *
 * @example
 * ```tsx
 * export default function () {
 * const { message } = useTypedLoaderData<typeof loader>();
 * const { setSnackbarMessage } = useSnackbar();
 *
 * setSnackbarMessage(message);
 *
 * return (
 *  <Layout>
 *    <Snackbar />
 *    <Outlet />
 *  </Layout>
 * );
 * }
 * ```
 *
 * @example
 * How to use with useSnackbar hook
 * ```tsx
 * export default function () {
 * const { showSnackbarMessage } = useSnackbar();
 *
 * return (
 *  <>
 *    <Snackbar />
 *    <button onClick={showSnackbarMessage('Hello World From Click')}>Click me!</button>
 *  </>
 * );
 * ```
 */
export const Snackbar = ({ className }: Props) => {
  const { message, key, show } = useSnackbar();

  if (!show) return null;

  return (
    <aside className={clsx('Snackbar', className)} key={key}>
      <p className="Snackbar-message">{message}</p>
    </aside>
  );
};
